import Alpine from 'alpinejs';
import { setup } from 'virtual:@astrojs/alpinejs/entrypoint';
setup(Alpine);
window.Alpine = Alpine;
Alpine.start();
import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://1a4fa4400acc7e2879ea40467bc1e236@sentry.gpmdata.tech/41",
  debug: false,
  environment: "production",
  release: "gpmdataru@1.14.2",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});